<template>
  <ZMainPage type="card">
    <div class="z-box-bg top-info mb-3">
      <div class="top-info-item" v-for="item in list0" :key="item.label">
        <div class="top-info-label">{{ item.label }}</div>
        <div class="top-info-value">
          <span :class="item.class">{{ item.value }}</span>
          <span class="text-primary ml-2 c-p" v-if="item.button" @click="handleView(item.type)">{{ item.button }}</span>
        </div>
      </div>
    </div>
    <div class="bg-white mt-2">
      <Tabs :index.sync="index" :list="['报名信息', '学生中考成绩', '后勤须知', '备注信息']" :cssStyle="{ fontSize: '20rem', padding: '0 20rem' }"></Tabs>
    </div>
    <div class="z-box-bg" v-if="index === 0">
      <LineWrapper class="list1" label-width="130rem">
        <LineItem :label="item.label" v-for="item in list1" :key="item.label">
          <span>{{ item.value }}</span>
          <span class="text-primary ml-1 c-p" v-if="item.button" @click="handleView(item.type)">{{ item.button }}</span>
        </LineItem>
      </LineWrapper>
    </div>
    <div v-else-if="index === 1">
      <div class="z-box-bg">
        <LineWrapper class="list1" label-width="130rem">
          <LineItem :label="item.label" v-for="item in list2" :key="item.label">
            <span>{{ item.value }}</span>
            <span class="text-primary ml-2 c-p" v-if="item.button" @click="handleView(item.type)">{{ item.button }}</span>
          </LineItem>
        </LineWrapper>
      </div>
      <div class="mt-2">
        <div class="text-primary page-title">中考考试成绩</div>
        <div class="z-box-bg">
          <DetailTable border :data="tableData2" :config="tableConfig2"></DetailTable>
        </div>
      </div>
      <div class="mt-2">
        <div class="text-primary page-title">诊断性考试成绩</div>
        <div class="z-box-bg">
          <DetailTable :data="tableData3" :config="tableConfig3"></DetailTable>
        </div>
      </div>
    </div>
    <div class="z-box-bg" v-else-if="index === 2">
      <LineWrapper class="list3" label-width="150rem">
        <LineItem :label="item.label" v-for="item in list3" :key="item.label">
          <span>{{ item.value }}</span>
          <span class="text-primary ml-2 c-p" v-if="item.button" @click="handleView(item.type)">{{ item.button }}</span>
        </LineItem>
      </LineWrapper>
    </div>
    <div class="z-box-bg" v-else-if="index === 3">
      <div class="list2" v-for="item in list4" :key="item.id">
        <div class="title">{{ item.label }}</div>
        <div class="content">{{ item.value }}</div>
      </div>
    </div>
    <Dialog :visible.sync="visible" :title="dynamicTitle" :size="dynamicSize" :loading="loading" submitText="我知道了" titleAlign="center" buttonAlign="center" :showCancelButton="false" :showClose="false" :closeOnClickModal="false">
      <template v-if="dynamicType === 1">
        <LineWrapper label-width="100rem" class="flex flex-column align-center" labelColor="#3c3c3c" :valueStyle="{fontWeight: 600}">
          <LineItem :label="item.label" :value="item.value" v-for="item in dialogList1" :key="item.label" style="width: 300rem;"></LineItem>
        </LineWrapper>
      </template>
      <template v-else-if="dynamicType === 2">
        <DetailTable border :data="tableData1" :config="tableConfig1"></DetailTable>
      </template>
      <template v-else-if="dynamicType === 3">
        <el-image :src="dialogSrc" lazy></el-image>
      </template>
      <template v-else-if="dynamicType === 0">
        <template v-if="dialogList2.length">
          <div class="my-1" v-for="item in dialogList2">
            <el-link type="primary" :href="item.other_url" target="_blank">{{ item.service_name }}</el-link>
          </div>
        </template>
        <div class="t-c pt-3 pb-2 text-muted" v-else>暂无数据</div>
      </template>
    </Dialog>
    <template #footer>
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </template>
  </ZMainPage>
</template>

<script>
import Dialog from "@/components/common/Dialog"
import Tabs from "@/components/common/Tabs"
import LineWrapper from "@/components/common/LineWrapper"
import LineItem from "@/components/common/LineItem"
import DetailTable from "@/components/common/DetailTable"
import { zsGet } from '@/api'
import { orderStatusColor } from '@/utils/data'
export default {
	_config:{"route":{"path":"detail","meta":{"title":"详情"}}},
  components: { Dialog, Tabs, LineWrapper, LineItem, DetailTable },
  data() {
    return {
      visible: false,
      loading: false,
      dynamicTitle: '',
      dynamicSize: '',
      dynamicType: '',
      index: 0,
      list0: [],
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      tableData1: [],
      tableConfig1: [],
      tableData2: [],
      tableConfig2: [],
      tableData3: [],
      tableConfig3: [],
      dialogSrc: '',
      dialogLoaded: false,
      dialogList1: [],
      dialogList2: [],
      info_id: ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      zsGet('/recruit/fresh-graduate/details-v2', { order_id: this.$route.query.id }).then(res => {
        // 顶部展示
        const { student_health: { crime_text, physiology, psychology }, regist_info: info, senior_score: score, service_notice: notice, remark_list = [] } = res
        this.info_id = info.info_id
        this.list0 = [
          { label: '订单状态', value: res.order_status, class: orderStatusColor[res.order_status] },
          { label: '学生姓名', value: res.student_name },
          { label: '性别', value: res.sex },
          { label: '家长姓名', value: res.parent_name },
          { label: '家长和学生的关系', value: res.relationship_name },
          { label: '是否可招收', value: res.recruit, button: `${res.recruit === '可招收' ? '查看详情' : ''}`, type: 1 },
        ]
        this.dialogList1 = [
          { label: '身体疾病', value: physiology },
          { label: '心理疾病', value: psychology },
          { label: '犯罪记录', value: crime_text },
        ]
        // 报名信息
        this.list1 = [
          { label: '报名就读学校', value: info.school },
          { label: '实际就读学校', value: info.reality_school },
          { label: '学籍所属学校', value: info.belong_school },
          { label: '学生当前状态', value: info.student_status },
          { label: '所属业务线', value: info.business_type || '普通生' },
          { label: '报名年级', value: info.grade_name },
          { label: '是否接受容缺政策', value: info.is_student_policy },
          { label: '是否参加预报名', value: info.is_pre_register, button: `${info.is_pre_register === '是' ? '查看详情' : ''}`, type: 2 },
          { label: '订单号', value: info.order_number },
          { label: '创建订单时间', value: info.order_created_at },
          { label: '支付金额', value: info.money_actual },
          { label: '签约手机号', value: info.sign_phone },
          { label: '支付订单号', value: info.payment_number || '-' },
          { label: '支付成功时间', value: info.pay_time || '-' },
          { label: '当前客户归属人', value: info.belong_teacher || '-' },
          { label: '报名招生老师', value: info.sign_teacher || '-'  },
          { label: '签约时间', value: info.sign_time || '-' },
          { label: '协议编号', value: info.agreement_sn, button: '查看', type: 0 },
        ]
        // 预报名列表
        this.tableConfig1 = [
          { prop: 'order_number', label: '预报名订单号' },
          { prop: 'belong_teacher_name', label: '预报名客户归属人' },
          { prop: 'money_actual', label: '实缴金额' },
          { prop: 'pay_time', label: '支付时间' }
        ]
        this.tableData1 = info.pre_register || []
        // 学生中考成绩
        this.list2 = [
          { label: '参加中考城市', value: score.senior_city },
          { label: '中考报名号', value: score.application_no || '-' },
          { label: '中考准考证号', value: score.admission_ticket_no || '-' },
          { label: '中考成绩证明图', value: score.score_image ? '' : '-',  button: `${score.score_image ? '查看详情' : ''}`, type: 3 },
          { label: '判定最高成绩考试', value: score.max_score_name },
          { label: '判定最高成绩总分', value: score.max_score_total },
          { label: '初中毕业学校', value: score.student_attend_graduation_school || '-' },
        ]
        // 中考考试成绩
        const { junior_score = [], diagnose_score = [] } = score
        this.tableConfig2 = junior_score.map(i => ({ label: i.subject_name, prop: 'prop' + i.subject_id }))
        let obj = {}
        junior_score.map(i => {
          const { subject_level = {}, subject_id } = i
          obj['prop' + subject_id] = subject_level.level || subject_level.score || ''
        })
        this.tableData2 = [obj]
        this.dialogSrc = score.score_image
        // 诊断性考试成绩
        this.tableConfig3 = diagnose_score.shift().map((i, j) => ({ label: i, prop: 'prop' + j }))
        this.tableData3 = diagnose_score.map((i, j) => {
          let obj = {}
          for (const key in i) {
            obj['prop' + key] = i[key] || 0
          }
          return obj
        })
        // 后勤须知
        this.list3 = [
          { label: '学生居住寝室', value: notice.dormitory_type },
          { label: '收件人', value: notice.receive_name },
          { label: '收件人手机号码', value: notice.receive_phone },
          { label: '收货地址', value: notice.receive_address },
        ]
        // 备注信息
        this.list4 = remark_list.map(i => ({ id: i.id, label: `${i.creator} 在 ${i.created_at} 提交了备注`, value: i.content }))
      }).finally(() => this.loading = false)

    },
    handleView(idx) {
      if(!idx && !this.dialogLoaded) {
        this.loading = true
        zsGet('/recruit/fresh-graduate/contract', { info_id: this.info_id }).then(res => {
          this.dialogList2 = res
          this.dialogLoaded = true
        }).finally(() => this.loading = false)
      }
      const titleArr = ['查看协议', '学生身心情况', '预报名订单', '中考成绩证明']
      this.visible = true
      this.dynamicType = idx
      this.dynamicSize = idx === 2 ? 'big' : 'small'
      this.dynamicTitle = titleArr[idx] || ''
    },
  }
}
</script>

<style lang="scss" scoped>
.top-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20rem;
  &-item {
    max-width: 240rem;
    margin-right: 80rem;
    font-size: 15rem;
    line-height: 32rem;
    display: flex;
    flex-shrink: 0;
  }
  &-label {
    margin-right: 30rem;
  }
  &-value {
    font-weight: 600;
  }
}
.list1 {
  display: grid;
  gap: 10rem 20rem;
  grid-template-columns: repeat(auto-fill, minmax(380rem, 1fr));
}
.list2 {
  .title {
    font-size: 18rem;
    margin-bottom: 16rem;
  }
  .content {
    min-height: 100rem;
    margin-bottom: 30rem;
    padding: 18rem 16rem;
    font-size: 16rem;
    color: #888;
    border-radius: 8rem;
    background-color: #FBFCFC;
  }
}
.list3 {
  display: grid;
  gap: 10rem 20rem;
}
.page-title {
  padding-left: 20rem;
  margin-bottom: 10rem;
  font-size: 20rem;
  font-weight: 600;
  line-height: 1.8;
}
</style>
