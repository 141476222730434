export const orderStatusColor = {
  已支付: 'text-blue',
  待支付: 'text-red',
  待审核: 'text-orange',
  待签约: 'text-purple',
  已签约: 'text-green',
  已取消: 'text-muted',
  签约中: 'text-purple',
  待完善: 'text-orange',
  支付失败: 'text-red',
  已退款: 'text-main',
}
